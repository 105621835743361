import React, {  useEffect } from 'react';
import { Redirect } from "react-router-dom"; //import Redirect first
import {
  //BrowserRouter as Router,
  Link, useLocation, 
} from 'react-router-dom';

import Cookies from 'universal-cookie';

import   {IsLogin, getUserCookie_ub36}    from '../../Portal/IsLogin'; 
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

//import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
//import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'; 
//import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
//import MailIcon from '@material-ui/icons/Mail';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import RestoreIcon from '@material-ui/icons/Restore';
//import NotificationsIcon from '@material-ui/icons/Notifications';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import MoreIcon from '@material-ui/icons/MoreVert';

import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';

import Divider from '@material-ui/core/Divider';

 
import LeftMenu from '../LeftMenu/LeftMenu';

//https://stackoverflow.com/questions/42081142/semantic-ui-react-how-to-use-link-components-in-menu-list-elements
//https://react.semantic-ui.com/#augmentation
//import Link from '@material-ui/core/Link'; 



const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    fontSize: '24px !important', display: 'block',
   /* display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },*/
  },
  title: {
   display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    } ,
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  //https://stackoverflow.com/questions/49145960/material-ui-bottomnavigation-is-not-sticky-by-default/49154688
  stickToTop: {
    width: '100%',
    position: 'fixed',
    top: 0,
  },
}));

export default function PrimarySearchAppBar() {


  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const [notaccountinitialsetup, setNotaccountinitialsetup] = React.useState(true);
  const [IsLoginState, setIsLoginState] = React.useState(false);

  const [redirect2URLState, setRedirect2URLState] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


  const location = useLocation();

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() { 
    setAnchorEl(null);
    handleMobileMenuClose(); 
  }

  function handleMenuCloseLogOut() { 
    setIsLoginState(false );
    setAnchorEl(null);
    handleMobileMenuClose(); 

  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

 
   
  


 function getViewmyprofileURL(){
  let URL="/profile";
let ub36 = getUserCookie_ub36();  
if( ub36!==null){
  URL= URL+"/"+ub36; 
}
return URL;
 }

    
   //https://betterprogramming.pub/tips-for-using-reacts-useeffect-effectively-dfe6ae951421
   //https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
 
   useEffect(() => { 

 const cookies = new Cookies();         
 let  accountinitialsetupC=cookies.get('accountinitialsetup');

    if(!window.location.pathname.includes("/accountinitialsetup" ) && accountinitialsetupC==="accountinitialsetup"){
    setRedirect2URLState(true) 
    } else{ 
      setRedirect2URLState(false);
    }

      if(window.location.pathname.includes("/inbox" )){
        setIsLoginState(true );
      } 
    setIsLoginState(IsLogin() );
    setNotaccountinitialsetup(!window.location.pathname.includes("/accountinitialsetup" ) )
  }, [location] )
 
 



  const menuId = 'primary-search-account-menu';
  const renderMenu =   (
    
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
       {IsLoginState ?  <MenuItem onClick={handleMenuClose}   underline='none' component={Link} to={getViewmyprofileURL()} >View my profile</MenuItem>:''}
       {IsLoginState ? <Divider />:''}
       {IsLoginState ? <MenuItem onClick={handleMenuClose}   underline='none'  component={Link} to="/editprofile">Edit profile</MenuItem>:''}
     {IsLoginState ? <MenuItem onClick={handleMenuClose}   underline='none'  component={Link} to="/settings">Profile setting</MenuItem>:''}
       {IsLoginState ? <MenuItem onClick={handleMenuClose}   underline='none'  component={Link} to="/accountsettings">Account settings</MenuItem>:''}
 
     {IsLoginState ?  <MenuItem onClick={handleMenuClose}   underline='none' component={Link} to="/membership">Membership</MenuItem>:''}
     
     
     
      
     {IsLoginState ? <Divider />:''}
    {IsLoginState ?  <MenuItem onClick={handleMenuCloseLogOut}  underline='none'   component={Link} to="/logout">Logout</MenuItem>:''}
     {!IsLoginState ?  <MenuItem onClick={handleMenuClose}  underline='none'   component={Link} to="/signup">Sign up</MenuItem>  :''}    
      {!IsLoginState ?  <MenuItem onClick={handleMenuClose}  underline='none'   component={Link} to="/login">Login</MenuItem>:''}

    </Menu>
  
  ) 
  ;

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu =    (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
       {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/search">
        <IconButton aria-label="" color="inherit">
           <RestoreIcon style={{color:'#005594'}} /> 
        </IconButton>
        
        <p>search</p>
      </MenuItem>:''}
      
      {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/inbox">
        <IconButton aria-label="" color="inherit">
          <Badge badgeContent={'@'} style={{fontSize:26}} color="secondary">
          <MailOutlinedIcon style={{color:'#005594'}} />
          </Badge>
        </IconButton>
        
        <p>Inbox</p>
      </MenuItem>:''}

      {IsLoginState ?<MenuItem  onClick={handleMenuClose}  underline='none' divider component={Link} to="/mylikes">
        <IconButton aria-label="My likes" color="inherit">
          {/* <Badge badgeContent={11} color="secondary">
             </Badge> */}
          <FavoriteBorderOutlinedIcon   />
         
        </IconButton>
        <p>My likes</p>
      </MenuItem>:''}
      {IsLoginState ?<MenuItem  onClick={handleMenuClose}  underline='none' divider component={Link} to="/whoslikedme">
        <IconButton aria-label="Who's liked me" color="inherit">
          {/* <Badge badgeContent={11} color="secondary">
             </Badge> */}
          <ThumbUpOutlinedIcon   />
         
        </IconButton>
        <p>Who's Likeded me</p>
      </MenuItem>:''}
      {IsLoginState ?<MenuItem  onClick={handleMenuClose}  underline='none' divider component={Link} to="/whosviewedme">
        <IconButton aria-label="Who's viewed me" color="inherit">
          {/* <Badge badgeContent={11} color="secondary">
              </Badge> */}
          <VisibilityOutlinedIcon   />
        
        </IconButton>
        <p>Who's viewed me</p>
      </MenuItem>:''}
       

      {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to={getViewmyprofileURL()}>
        {/* <IconButton
          aria-label="View my profile"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>View my profile</p>
      </MenuItem>:''}



 

      {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/editprofile">
        {/* <IconButton
          aria-label="my setting"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>Edit profile</p>
      </MenuItem>:''}


      {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/settings">
        {/* <IconButton
          aria-label="Profile setting"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>Profile setting</p>
      </MenuItem>:''}
    
     {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/accountsettings">
        {/* <IconButton
          aria-label="Account settings"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton> */}
        <p>Account settings</p>
      </MenuItem>:''}


      {IsLoginState ?<MenuItem onClick={handleMenuClose}  underline='none' divider component={Link} to="/membership">
        {/* <IconButton
          aria-label="manage team"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        > 
          <AccountCircle />

        </IconButton>*/} 
         {/* <i className="material-icons " style={{fontSize:24, paddingRight:10}}  aria-label="Membership"></i> */}
        <p>Membership</p>
      </MenuItem>:''} 

      {IsLoginState ? <MenuItem onClick={handleMenuCloseLogOut} underline='none'   component={Link} to="/logout">
      {/* <IconButton
          aria-label="logout"
          aria-controls="primary-account-login-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>  */}
        <p>Logout</p></MenuItem>:''} 
      {!IsLoginState ?<MenuItem onClick={handleMenuClose}   underline='none'  component={Link} to="/signup">
      {/* <IconButton
          aria-label="Sign up"
          aria-controls="primary-account-signup-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <PersonPinCircleIcon />
        </IconButton> 
        <p>Sign up</p> the mobile is better without <p></p>
        */}
      Sign up</MenuItem>:''}
        {!IsLoginState ? <MenuItem onClick={handleMenuClose} underline='none'   component={Link} to="/login">
      {/* <IconButton
          aria-label="Login"
          aria-controls="primary-account-login-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <InputIcon />
        </IconButton> 
        <p>Login</p>
        */}
         Login </MenuItem>:''} 
    </Menu>
  );

  return (



    <div className={classes.grow}> 
      { (redirect2URLState )?  (<Redirect to={"/accountinitialsetup"} />)   : ''}
      <AppBar position="fixed">
        <Toolbar>
          {/* <IconButton 
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer" >
            <MenuIcon />  
          </IconButton> */}
        
        {notaccountinitialsetup?   <LeftMenu/> :''}

         
          <Typography className={classes.logo }   variant="h6" noWrap>
           Let's cupid 
          </Typography>
        {/*    <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
*/ }



          <div className={classes.grow} />
         
          <div className={classes.sectionDesktop}>
          {(IsLoginState &&  notaccountinitialsetup)  ?   <IconButton title="Search"  aria-label="" color="inherit"  component={Link} to="/search">
            <RestoreIcon style={{color:'#ffffff'}}/>  
            </IconButton>:''}

         {(IsLoginState &&  notaccountinitialsetup)  ?   <IconButton title="Inbox"  aria-label="" color="inherit"  component={Link} to="/inbox">
              <Badge badgeContent={'@'} style={{fontSize:26}} color="secondary">
                <MailOutlinedIcon style={{color:'#ffffff'}} />
              </Badge>
            </IconButton>:''}

            {(IsLoginState &&  notaccountinitialsetup)  ?    <IconButton title="My likes" aria-label="My likes" color="inherit" component={Link} to="/mylikes">
              {/* <Badge badgeContent={35} color="secondary">
                 </Badge> */}
                <FavoriteBorderOutlinedIcon style={{color:'#ffffff'}} />
             
            </IconButton>:''}
            {(IsLoginState && notaccountinitialsetup)  ?    <IconButton title="Who's liked me" aria-label="Who's liked me" color="inherit" component={Link} to="/whoslikedme">
              {/* <Badge badgeContent={42} color="secondary">
                  </Badge> */}
                <ThumbUpOutlinedIcon style={{color:'#ffffff'}} />
            
            </IconButton>:''}
            {(IsLoginState &&  notaccountinitialsetup)  ?    <IconButton title="Who's viewed me" aria-label="Who's viewed me" color="inherit" component={Link} to="/whosviewedme">
              {/* <Badge badgeContent={17} color="secondary">
                </Badge>  */}
                <VisibilityOutlinedIcon style={{color:'#ffffff'}} />
             
            </IconButton>:''}
        
        {notaccountinitialsetup?
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> 
            :''
              }

        
          </div>
          <div className={classes.sectionMobile}>
          {notaccountinitialsetup?
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
             :''
            }
             
          </div>
     
        </Toolbar>
      </AppBar>

      {notaccountinitialsetup?   renderMobileMenu :''}
      {notaccountinitialsetup? renderMenu :'' }
    </div>
  );
}
