import React from 'react';
//import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom';

// import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
// import { createStore, applyMiddleware, compose } from 'redux';
// import rootReducer from './rootReducer';
import setAuthorizationToken from './utils/setAuthorizationToken';
// import jwtDecode from 'jwt-decode';
// import { setCurrentUser } from './actions/authActions';



//https://www.codingame.com/playgrounds/9396/redux-form-validation-tutorial
  
//import { reducer as formReducer } from 'redux-form';


 import App from './App';
import './index.css';

import * as serviceWorker from './serviceWorker';



//https://reactgo.com/react-global-variable/
 
 //window.$__imgCDNURL = 'https://www.letscupid.com'; //global variable
 // must be this when CDN ready window.$__imgCDNURL = 'https://lhcfmsftprotos.azureedge.net'; //global variable
 //window.$__imgCDNURL = 'https://lhcfimgpremblob.z20.web.core.windows.net'; //global variable
 window.$__imgCDNURL = 'https://letscupidmsftcdnprotos.azureedge.net/'; 
 window.$__imgCDNURL = 'https://letscupidmsftcdnprotos.letscupid.com/'; 

 window.$__ver = "1.0.0"


 //change this conditionaly if localhost that
 if(window.location.hostname ==='localhost')
 {
   window.$__APIURL = 'https://localhost:6400'; //local/public variable 
   window.$__WebSiteDomain = 'localhost:6400'; //local/public variable  
 }
 else{
  window.$__APIURL = 'https://api2cdn.letscupid.com'; //local/public variable
  window.$__WebSiteDomain = '.letscupid.com'; //local/public variable 
 }

 //photos change to CDN because the guid of the photos 
 window.$__PHOTOSCDN ='https://letscupidmsftcdnprotos.letscupid.com/';


 // window.$__table_lhcfverpremtable = 'https://letshavecoffeefirstback.table.core.windows.net/'; 
 window.$__table_lhcfverpremtable = 'https://letscupideus2tableprod1.azureedge.net/'; 
 
  
 //CDN for JSONs
//window.$__APIURLJSONCDN ='https://lhcfimgpremblob.z20.web.core.windows.net/';
  window.$__APIURLJSONCDN ='https://letscupidmsftcdnprotos.letscupid.com/'; 
 
 

 

//import routes from './Routes/Routes';
 


 
 /* 
ReactDOM.render(
  routes ,
    document.getElementById('root')
    );
   */
  

 

// const store = createStore(
//   rootReducer,
//   compose(
//     applyMiddleware(thunk),
//     window.devToolsExtension ? window.devToolsExtension() : f => f
//   )
// );

if (localStorage.jwtToken) {
  setAuthorizationToken(localStorage.jwtToken);
 // store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
}

   

ReactDOM.render(
  <App /> 
 , document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

 