//https://material-ui.com/components/bottom-navigation/

import React  from 'react'; 
import {   Link , useLocation} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
// import BarChart from '@material-ui/icons/BarChart';


 //Google analytics
//https://stackoverflow.com/questions/49279820/adding-google-analytics-to-react
//import ReactGA from 'react-ga';


// import LocalCafeIcon from '@material-ui/icons/LocalCafe';
// import FavoriteIcon from '@material-ui/icons/Favorite';

import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
  
//import LocationOnIcon from '@material-ui/icons/LocationOn';
//import Badge from '@material-ui/core/Badge';

//import { FunctionsRounded } from '@material-ui/icons';
//import { red } from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
   // width: 968,
    
  },
  stickToBottom: {
    width: '100%',
    zIndex:2147483647
    // position: 'fixed',
    // bottom: 0,
  },
  
  selected:{ 
     backgroundColor:'#DBF3FD',
  },
});

export default function FooterBottomNavigation() {
  const classes = useStyles();

 
  const [value, setValue] = React.useState(0);
  const [active, setActive] = React.useState("");


//https://stackoverflow.com/questions/45373742/detect-route-change-with-react-router
const location = useLocation();
React.useEffect(() => {

  function setStateA (state){
    setActive(state);
   };


   function URL2Title(url){
if(url.includes("/profile/")){
  return "Profile"
}

     let title="Let's cupid";
     switch(url) {
      case "/":
        title="Let's cupid";
        break;
      case "/search":
        title="Search";
        break;
      // case "/invitations":
      //   title="Invitations";
      //   break;
      // case "/myinvites":
      //     title="My invitations";
      //     break;

         case "/mylikes":
              title="My Likes";
              break;

          case "/whoslikedme":
              title="Who's liked me";
              break; 
         case "/whosviewedme":
              title="Who's viewed me";
              break;   

       case "/inbox":
            title="Inbox";
            break;

     
         case "/editprofile":
             title="Edit profile";
             break;  
         case "/accountsettings":
             title="Account settings";
              break;  
        case "/membership":
                title="Membership";
                 break;  
        case "/pricing":
         title="Pricing";
         break;            
         case "/about-us":
          title="About us";
          break;                   
          case "/safety":
            title="Safety";
            break; 
            case "/cookie-policy":
              title="Cookie policy";
              break; 
           case "/privacy-policy":
              title="Privacy policy";
              break;             
            case "/terms-of-service":
                title="Terms of service";
                break; 
                case "/refund-policy":
                  title="Refund policy";
                  break;    
          case "/contact":
                  title="Contact";
                  break;   
                  case "/support":
                    title="Support";
                    break;   
      case "/login":
            title="Login";
            break;   
         case "/signup":
              title="Signup";
              break;    
        case "/restorepassword":
                title="Restore password";
                break;                                                   
      default:
        title="Let's cupid";
    }
     
    return title;
   }//end function URL2Title(url){

 //for GTAG
 //https://stackoverflow.com/questions/49322314/react-spa-gtm-analytics-react-helmet-previous-page-title
 //EXPLAIND !!! https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics/
 //console.log('Location changed ' + window.location.pathname); 
const firePageViewEvent = () => {
  setTimeout(() => {
      //console.log("Pageview event fired (from tracking script)"); 

      if (window && window._tfa) {
        window._tfa = window._tfa || [];
        window._tfa.push({notify: 'event', name: 'page_view', id: 1388859});         
      }


      if (window && window.dataLayer) {
        let urlPath = window.location.pathname + window.location.search;
        let urlTitle=URL2Title(urlPath) ;//document.title;
        document.title=urlTitle;
       //   console.log("window and dataLayer exist, pushing pageview event.");
         //   console.log(document.title)
    //  console.log(window.location.pathname + window.location.search);
          //https://www.bounteous.com/insights/2018/03/30/single-page-applications-google-analytics/
         // console.log( 'window.dataLayer')
         // console.log( window.dataLayer)
          let dataLayer = window.dataLayer || [];
            dataLayer.push({
             event: 'pageview',
             page: {
              path:urlPath,
              title: urlTitle
            }
          });
      } else {
          console.log("window or dataLater does not exist, cannot push pageview event.");
      }

      const getQueryString = url => { 
        const image = document.createElement('img');  
        var referrerMiniMe = document.referrer;
        image.height = 1;
        image.width = 1;
        image.src  = 'https://analyticsminimevzpr.azureedge.net/api/onepixel?code=M1rsfa4IR2vrGmonqDo0hFD5wffC3MAiISH0u4Qf5NLthz729h87Hw==&pk=letsqupid&' + 'referring_domain_querystring=' + encodeURIComponent(url) + '&referrerMiniMe='+referrerMiniMe;
        document.querySelector('body').appendChild(image);
       } 
       getQueryString(window.location); 

  }, 30);
};
 
firePageViewEvent();

 
 

//   console.log('Location changed ' + window.location.pathname);
//  console.log(document.title)
//  console.log(window.location.pathname + window.location.search);

  const   URLName  =window.location.pathname;  
    // var footerURLs = ["/invitations", "/myinvites", "/" ];
    // var n = footerURLs.includes("Mango"); 
     setStateA( "" )
    if(URLName === "/search"  ){
      setStateA("search" )
    }
    if(URLName === "/whoslikedme" ){ 
      setStateA( "whoslikedme" )
    }

    if(URLName === "/whosviewedme" ){ 
      setStateA( "whosviewedme" )
    }
    // if(URLName === "/invitations" ){ 
    //   setStateA( "invitations" )
    // }
   
    
    if(URLName === "/accountinitialsetup" ){
      setStateA("accountinitialsetup" )
    } 

}, [location]);


 
 
  return (

    (active === "accountinitialsetup")?'':
    <BottomNavigation   style={{  zIndex:2147483647, backgroundColor:'#ffffff'}}
      value={value}
      onChange={(event, newValue) => { 
        setValue(newValue);
      }}
      showLabels
      //className={classes.stickToBottom}
      className="stickToBottom"
    >
      <BottomNavigationAction key="search"  
    onClick={() => setActive( "search" ) }
    className={(active === "search")? classes.selected :''} 
      label="Search" component={Link} to={"/search"} icon={<RestoreIcon />} />
       <BottomNavigationAction  key="whoslikedme" 
        className={(active === "whoslikedme")? classes.selected :''}  
        onClick={() => setActive( "whoslikedme")}
       label="Who's liked me"  component={Link} to={"/whoslikedme"} icon={ <ThumbUpOutlinedIcon />} />

      <BottomNavigationAction   key="whosviewedme" 
    onClick={() => setActive("whosviewedme")}
    

    className={(active === "whosviewedme")? classes.selected :''}  
      label="Who's viewed me"   component={Link} to={"/whosviewedme"} icon={<VisibilityOutlinedIcon />} />
      {/* <BottomNavigationAction label="blobs"   component={Link} to={"/blobs"} icon={<BarChart />} /> */}
    

    </BottomNavigation>
   
  );
}
